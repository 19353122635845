
.timeline_week_wrapper {
    position: relative;
    background-color: white;
    margin-bottom: 20px;
}

.timeline_summary {
    display: inline-block;
    height: auto;
    width: 100%;
    position: relative;
}

.money_summary h2 {
    color: #fffd54;
    font-size: 15px;
    padding: 0;
    margin: 0;
    text-align: center;
}

.timeline_ladder {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 12px;
    border-radius: 20px;
    width: 20px;
    background-color: black;
    z-index: 1;
}

.target_drawer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 230px;
    top: 0px;
    bottom: 25px;
    background-color: yellow;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    box-shadow: 4px 4px 4px rgba(0,0,0,0.7);
    transition: 500ms ease-in-out;
}

.target_image_wrapper {
    flex: 1;
    padding-left: 15px;
    padding-top: 10px;
}

.target_drawer_container {
    flex: 3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.target_drawer_row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
}  

.target_image_wrapper img {
    padding-left: 10px;
    width: 70%;
    height: 50%;
}

.target_icon_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    padding-left: 7px;
}

.target_icon_wrapper img {
    width: 30px;
    height: 30px;
}

.target_icon_wrapper h3 {
    padding: 0;
    margin: 0;
    padding-top: 5px;
}
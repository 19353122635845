.min_header {
    height: 10vh;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.back_button_container {
    flex-basis: 12%;
    padding-top: 10px;
    align-items: center;
    justify-content: center;
    display: flex;    
    cursor: pointer;
}

.back_button_container h1 {
    transform: rotate(180deg);
}

.header_container  {
    flex-basis: 80%;
    display: flex;
    align-items: center;
}

.header_container h1 {
    font-size: 25px;
    padding-top: 7px;
    padding-right: 10px;
}

* {
    text-decoration: none;
    list-style: none;
}


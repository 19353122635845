.error_wrapper {
    margin: auto;
    margin-top: 40px;
    padding: 0px;
    font-size: 18px;
}

.error_wrapper ul {
    text-align: center;
    padding: 0;
}

.error_wrapper li {
    width: 33%;
    margin: auto;
    background-color: black;
    border-radius: 15px;
    color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: 4px 4px 4px rgba(0,0,0,0.7);
}
.error_wrapper p {
    text-align: center;
}
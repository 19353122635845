.timeline_row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-right: 5px;
}

.entry_dot {
    width: 40px;
    height: 40px;
    margin-left: 0px;
    border-radius: 50%;
    margin-top: 10px;
    z-index: 89;
    box-shadow: 4px 4px 5px rgb(124, 122, 122);
    border: solid 1px grey;
    display: flex;
    align-items: center;
    justify-content: center;
}

.entry_dot h1 {
    font-size: 15px;
}

.expanded_underline {
    width: 100%;
    height: 5px;
    border-radius: 20px;
    margin: 10px 0;
}

.expanded_detail_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 10px;
}

.expanded_info_tab {
    flex-basis: 50%;
}

.expanded_info_target {
    flex-basis: 50%;
}

.expanded_info_heading {
    padding: 12px 10px;
}

.expanded_info_heading h2 {
    padding: 0;
    margin: 0;
    font-size: 20px;
    padding-left: 10px;
}

.expanded_info_target {
    display: flex;
    justify-content: center;
}

.expanded_info_target img {
    width: 80%;
    height: 80%;
    padding-top: 20px;
}

.expanded_bottom_tab {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
}

.expanded_notes {
    flex-basis: 70%;
    display: flex;
    flex-direction: column;
    padding-left: 0px;
}

.expanded_notes h2 {
    margin: 0;
    padding: 0;
}

.expanded_notes p {
    margin: 0;
    padding: 0;
    padding-top: 5px;
    font-size: 15px;
    padding-bottom: 10px;
}

.plus_icon {
    flex-basis: 30%;
    display: flex;
    align-items: center;
    justify-content: right;
}

.entry_bubble_collapsed {
    height: auto;
    min-height: 10px;
    width: 80%;
    margin-left: 10px;
    margin-top: 5px;
    padding: 10px; 
    background-color: pink;
    border-radius: 20px;
    transition: min-height 500ms ease-in-out;
    position: relative;
    box-shadow: 4px 4px 5px rgb(124, 122, 122);
}

.entry_bubble_expanded {
    min-height: 200px;
    width: 80%; 
    margin-left: 10px;
    margin-top: 5px;
    padding: 10px; 
    background-color: pink;
    border-radius: 20px;
    transition: min-height 500ms ease-in-out;
    box-shadow: 4px 4px 5px rgb(124, 122, 122);
}

.entry_bubble_empty_collapsed {
    width: 200px;
    height: 50px;
    margin-top: 5px;
    border-radius: 20px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: left;
}

.entry_bubble_empty_expanded {
    width: 70%;
    height: 200px;
    margin-top: 5px;
    border-radius: 20px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: 4px 4px 5px rgb(124, 122, 122);
}

.entry_bubble_empty_expanded img {
    height: 40%;
    width: 40%;
}

.entry_bubble_empty_collapsed img {
    height: 40%;
    width: 40%;
}

.bubble_content_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bubble_content_column {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 90%;
    padding-right: 20px;
}

.bubble_content_row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.bubble_content_row img {
    height: 40%;
    width: 40%;
}

.bubble_content_wrapper_empty {
    height: 100%;
    width: 100%;
}

.plus_icon_hide {
    display: none;
    flex: 1;
}

.plus_icon_shown {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.empty_target_image_open {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.empty_target_img_closed {
    display: flex;
    align-items: center;
    justify-content: center;
}

.expanded_notes textarea {
    background-color: white;
    border: 1px solid black;
    width: 95%;
    padding-bottom: 10px;
    height: 50px;
    font-size: 18px;
    resize: none;
    font-weight: normal;
    color: rgb(50,50,50);
    font-family: 'Arial, Helvetica, sans-serif';
}

textarea::placeholder {
    color: rgb(50,50,50)
}

.target_indicator {
    right: 20px;
    bottom: -20px;
    height: 40px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    width: 50px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
         box-shadow: 0 8px 6px -6px black;
}

.target_indicator img {
    width: 70%;
    height: 70%;
}


.empty_plus_wrapper h1 {
    font-size: 30px;
}

.unconfirmed_hide {
    display: none
}

.unconfirmed_show {
    flex-basis: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.unconfirmed_row {
    background-color: black;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.unconfirmed_row_plus {
    margin-top: 0px;
}

.unconfirmed_row h1 {
    font-size: 20px;
    text-align: center;
}

.bubble_content_row h4 {
    font-size: 20px;
}
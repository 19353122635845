html {
    height: 100%;
    width: 100%;
    background-color: white;
}

body {
    height: 100%;
    background-color: white;
    min-width: 340px;
}

h1, h2, h3, h4, h5, p {
    font-family: "Zuume";
    letter-spacing: 1px;
}

::placeholder {
    font-family: "Zuume";
    letter-spacing: 1px;
}

#root {
    height: 100%;
    background-color: white;
}

@font-face {
    font-family: 'Zuume';
    src: local('Zuume Cut Bold'), local('Zuume-Cut-Bold'),
        url('./fonts/ZuumeCut-Bold.woff2') format('woff2'),
        url('./fonts/ZuumeCut-Bold.woff') format('woff'),
        url('./fonts/ZuumeCut-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

.background_wrapper {
    height: 100%;
    width: 100%;
    background-size: cover;
    position: fixed;
    overflow-y: scroll;
}

.content_wrapper {
    background-color: #fff;
    position: relative;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.summary_icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    float: left;
    margin: 0 15px;
    padding: 10px 0; 
}

.summary_icon_right {
    float: right;
}

.summary_icon img {
    width: 40px;
    height: 40px;
}
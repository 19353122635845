.login_wrapper h1 {
    color: #fffd54;
    padding-left: 55px;
}


.logo {
    margin-left: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 0px;
}

.logo img {
    width: 100%;
    height: 100%;
    max-width: 400px;
    max-height: 100px;
}

.input_wrapper {
    padding-top: 10px;
}

.banner_image_container {
    height: 20vh;
    overflow: hidden;
}

.banner_image_container img {
    width: 100%;
    height: 100%;
    clip-path: polygon(30% 0%, 70% 0%, 100% 0, 100% 100%, 66% 96%, 23% 81%, 0 57%, 0 0);
}

.login_options_container {
    display: flex;
    flex-direction: row;
    justify-content: right;

    width: 85%;
    margin: 10px auto;
}

.remember_switch {
    flex-basis: 50%;
    display: flex;
    flex-direction: row;
}

.remember_switch h3 {
    flex-basis: 65%;
    font-size: 12px;
    padding: 16px 0;
    margin: 0;
    color: #fffd54;
    text-align: right;
}

.switch {
    flex-basis: 35%;
}

.forgot_password h3 {
    flex-basis: 65%;
    margin: 0;
    text-align: right;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 14px;
    cursor: pointer;
}

#remember_switch {
    padding-left: 5px;
}

.logo_name {
    font-family: "Zuume";
}

.button_wrapper h3 {
    flex-basis: 65%;
    margin: 0;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    cursor: pointer;
}

.header_spaceing {
    padding-top: 10vh;
}
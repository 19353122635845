.entry_bubble_shoe_collapsed {
    width: 80%;
    height: 70px;
    margin-top: 5px;
    border-radius: 20px;
    margin-left: 10px;
    display: flex;
    align-items: left;
    justify-content: right;
    box-shadow: 4px 4px 5px rgb(124, 122, 122);
}

.empty_target_img_closed {
    display: flex;
    width: 100%;
}

.empty_shoe_column {
}

.empty_steps_column {
    width: 80%;
}

.empty_shoe_column img {
    width: 50px;
    margin-left: 20px;
    margin-right: 20px;
}

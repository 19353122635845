.cancelbutton_wrapper {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 500ms ease-in-out;
    color: rgb(255, 255, 255);
    margin: 0px;
}

.cancelbutton_wrapper:hover {
    background-color: white;
    color: black;
    transition: 500ms ease-in-out;
}
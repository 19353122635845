.pinlock_code_input {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: auto;
}

.pinlock_code_input h3 {
    flex-basis: 50%;
    padding-left: 20px;
    font-size: 20px;
}

.pinlock_input_wrapper {
    flex-basis: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pinlock_input_wrapper input {
    border: none;
    width: 100px;
    height: 30px;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 20px;
    color: white;
    text-align: center;
}
.participant_information p {
    font-family: Arial, Helvetica, sans-serif;
}

.participant_information img {
    width: 20%;
    margin-left: 70%;
}


.participant_information li {
    list-style-type: square;
    margin-bottom: 8px;
}

.consent_checkboxes .checkbox {
    display: flex;
    flex-direction: row;
    align-items:center;
    min-height: 80px;
}

.consent_checkboxes .checkbox label {
    margin-left: 5px;
    margin-right: 5px;
}
.input_box_container {
    height: auto;
    width: 100%;
    margin-top: 24px;
}

.input_box_container h1 {
    font-size: 16px;
    text-align: left;
    margin-bottom: 0;
    padding-left: 65px;
}

.input_box {
    width: 75%;
    display: flex;
    margin: auto;
}

.input_box input {
    width: 100%;
    height: 55px;
    display: block;
    margin-top: 5px;
    border: none;
    padding-left: 55px;
    font-size: 20px;
    border-radius: 30px;
}

.input_image {
    position: absolute;
    height: 30px;
    width: 30px;
    overflow: hidden;
    align-self: center;
    padding-left: 13px;
    padding-top: 6px;
}

.input_image img {
    height: 100%;
    width: 100%;
}

.input_box input::placeholder {
    color: #7c8522;
}

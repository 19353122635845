@media only screen and (min-width: 700px){
    .header_spaceing {
        padding-top: 15vh;
    }

    .banner_image_container {
        height: 40vh;
        overflow: hidden;
        position: fixed;
        top: 0;
        right: 0;
        width: 50%;
    }

    header {
        
        padding: 15px 0;
    }

    .banner_image_container img {
        width: 100%;
        height: 100%;
        clip-path: polygon(0% 0%, 0% 0%, 100% 0, 100% 100%, 50% 100%, 5% 80%, 0px 57%, 0 0);
    }

    .content_wrapper {
        width: 50%;
        margin-left: 50px;
        height: 100%;
    }

    .entry_modal {
        width: 50%;
        margin-left: 50px;
        height: 100%;
    }

    .login_wrapper h1 {
        padding-left: 65px;
        display: inline-block;
        font-size: 20px;
    }

    #logo_header {
        font-size: 70px;
    }

    .forgot_password h3 {
        padding: 15px 40px;
    }

    .login_background {
        height: 100%;
    }

    .about_container {
        width: 50%;
    }

    .profile_image_wrapper {
        align-items: center;
    }

    .settings_container {
        width: 35%;
    }

    .banner_image_container {
        display: none;
    }


    .logo {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 100px;
        padding-bottom: 50px;
    }

    .logo img {
        width: 100%;
        height: 100%;
        max-width: 300px;
        max-height: 100px;
    }
}

@media only screen and (min-width: 500px){
    .input_box_container h1 {
        padding-left: 70px;
    }
}

@media only screen and (max-width: 500px){
    .logo {
        width: 30%;
    }

    .logo_wrapper {
        padding-left: 0px;
    }
}

@media only screen and (min-width: 1100px){
    .content_wrapper {
        width: 40%;
    }

    .entry_modal {
        width: 40%;
    }
}

@media only screen and (min-width: 1600px){
    .input_box_container h1 {
        padding-left: 85px;
    }
}
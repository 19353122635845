.about_wrapper {
    padding: 0 20px;
    font-size: 18px;
}

.about_links {
    padding: 0 20px;
}

.about_links h2 {
    margin: 0;
    padding-bottom: 10px;
}

.about_links h3 {
    padding: 0;
    margin: 0;
    font-weight: normal;
}
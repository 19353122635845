h1, h2 {
    margin-left: 20px;
    color: black; 
    width: "90%";
}

p {
    margin-left: 20px; 
    color: black; 
    width: "90%";
}

.info_sheet img {
    margin-top: 30px;
}
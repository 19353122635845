.button_container {
    width: 60%;
    height: 7vh;
    margin: auto;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 4px 4px 5px rgb(124, 122, 122);
}

.login .button_container {
    min-height: 50px;
}

.button_container h1 {
    font-size: 20px;
    color: white;
    text-align: center;
    padding: 0;
    padding-left: 10px;
    padding-right: 10px;
}

#button_label {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
}
.entry_modal {
    position: fixed;
    z-index: 91;
    height: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
    display: block;
}

.entry_modal_row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
    margin: 20px 0;
}

.entry_modal_column {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.plusbutton_container {
    width: 100%;
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.entry_modal_input {
    margin: 0 auto;
    border: 1px solid black;
    border-radius: 40px;
    padding: 20px 10px;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
}


.footer {
    background-color: lightgrey;
    min-height: 50px;
    margin-top: 50px;
    padding-bottom: 100px;
    padding-top: 20px;
    border-top: 2px solid black;
    box-shadow: 0 50vh 0 50vh lightgrey;

}
.footer h2, .footer p {
    width: 85%;
    margin: auto;
    margin-top: 10px;
    font-size: 1em;
}
.footer p {
    font-size: 0.8em;
    font-family:'Times New Roman', Times, serif
}
.question_switch {
    display: flex;
    width: 90%;
    margin: 10px auto;
    flex-direction: row;
}

.question_switch h3 {
    align-self: flex-start;
    flex-basis: 50%;
    padding-left: 20px;
    font-size: 20px;
}

.switch_wrapper { 
    flex-basis: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.switch {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    padding-left: 40px;
}


header {
    margin-left: 0px;
    margin-bottom: 0px;
    padding-top: 10px;
    width: 100%;
    z-index: 100;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #fffd54;
    box-shadow: 4px 8px 20px rgb(124, 122, 122);
}

.logo_container {
   
    flex-basis: 50%;
    text-align: left;
    font-size: 30px;
    margin: 0;
}

.logo_container img {
    max-width: 250px;
    max-height: 70px;
}

.burger_container {
   
    height: 100%;
    text-align: right;
    padding-right: 30px;
    cursor: pointer;
}

.burger_container h4 {
    font-size: 48px;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: bold;
    color: #fffd54;
    transform: none;
    transition-duration: 0.3s; /* give it a rotation effect when checked */
}

.burger_container h4.rotated {
    transform: rotate(90deg);
}

.logo_container h4 {
    font-size: 48px;
    padding: 0;
    margin-top: 20px;
}

.burgernav {
    background-color: #fffd54;
    max-height: 100%;
    display: none;
    z-index: 90;
    box-shadow: 4px 8px 10px rgb(124, 122, 122);
    padding-top: 0px;
    padding-right: 30px;
    margin-top: 0px;
}

.burgernav li {
    color: #fffd54;
    text-align: right;

    font-weight: bold;
    font-size: 50px;
    font-family: "Zuume";
    letter-spacing: 1px;
    text-shadow:
   -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;
}

.burgernav h2 {
    color: #fffd54;
    font-weight: bold;
    font-size: 40px;
    display: inline-block;
    margin-right: 30px;
    cursor: pointer;
}

.close_wrapper {
    display: flex;
    justify-content: flex-end;
}

.logo_wrapper {
    width: 200px;
    padding-left: 20px;
}

.logo_wrapper img {
    
    width: 100%;
}

ul {
    cursor: pointer;
    margin-top: 0px;
}
.profile_image_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.profile_image {
    margin-top: 50px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
}

.profile_image_wrapper h3 {
    margin: 10px 0 0 0;
    padding: 0;
    font-size: 22px;
}

.profile_image_wrapper h4 {
    margin: 0;
    padding: 0;
    font-size: 15px;
    font-weight: normal;
}

.profile_image img {
    width: 100%;
    height: 100%;
}

.settings_list ul {
    padding-top: 30px;
    padding-left: 0px;
}

.settings_list li {
    font-size: 26px;
    font-weight: 500;
    padding: 5px;
    padding-top: 40px;
    cursor: pointer;
    font-family: "Zuume";
    letter-spacing: 1px;
    padding-left: 20px;
}

.notification_entry {
    padding-left: 5px;
    display: flex;
}

.notification_entry h3 {
    font-size: 26px;
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 10px;
}

.switch_container {
    display: flex;
    flex-direction: column;
    padding-right: 60px;
    flex-basis: 50%;
    margin-top: 15px;
    align-items: flex-end;
    justify-content: center;
}
.plusbutton_wrapper {
    width: 100px;
    height: 40px;
    border-radius: 14%;
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 500ms ease-in-out;
    color: white;
    margin: 0px;
    box-shadow: 4px 4px 5px rgb(124, 122, 122);

}

.plusbutton_wrapper:hover {
    background-color: white;
    color: black;
    transition: 500ms ease-in-out;
}